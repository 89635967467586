/* eslint-disable global-require */
import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Breadcrumbs from 'components/Breadcrumbs';
import ButtonDefault from 'components/ButtonDefault';
import TitleDefault from 'components/TitleDefault';
import color from 'styles/color';

const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  padding: 20px 0;

  .col-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    h3 {
      font-size: 22px;
      color: ${color.gunsmoke};
      margin-bottom: 40px;
    }

    .whatsapp {
      margin: 30px 0 40px;
    }

    .send-message {
      margin: 20px 0 0;
    }
  }
`;

const Content = () => (
  <Fragment>
    <Breadcrumbs>Contato</Breadcrumbs>
    <Container className="">
      <WrapperRow>
        <Col md="12">
          <Row>
            <Col className="col-content">
              <TitleDefault>
                Envie sua mensagem para nós. Ficaremos felizes em te responder!
              </TitleDefault>

              <h3>Selecione uma das opções abaixo:</h3>

              <ButtonDefault className="send-message">
                Enviar E-mail
              </ButtonDefault>

              <ButtonDefault className="whatsapp" color={color.greenLight}>
                Enviar WhatsApp
              </ButtonDefault>

            </Col>
          </Row>
        </Col>
      </WrapperRow>
    </Container>
  </Fragment>
);

export default Content;
