const color = {
  primary: '#0B6760',
  primaryLight: '#E2F4EE',
  primaryMiddle: '#08504A',
  grey: '#566160',
  greyMiddle: '#5E7775',
  greyLight: '#eeefef',
  green: '#5E7775',
  greenLight: '#00DA4E',
  gold: '#DBBA46',

  gunsmoke: '#858F8E',
  wildSand: '#F4F4F4',
  blackHaze: '#F8F9F9'

};

export default color;
