import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const LinkStyled = styled(Link)`
  color: #fff;

  &:hover {
    text-decoration: underline;
    color: #fff;
  }
`

const LinkDefault = ({ children, to }) => (
  <LinkStyled to={to}>
    {children}
  </LinkStyled>
)

LinkDefault.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
}

export default LinkDefault
