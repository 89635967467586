/* eslint-disable global-require */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import ButtonDefault from 'components/ButtonDefault';
import color from 'styles/color';
import { Link } from 'react-router-dom';

const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${color.primaryLight};
  height: auto;
  padding: 20px 0;

  .col-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .banner-main-topic {
      color: ${color.grey};
      font-weight: bold;
      font-size: 26px;
    }

    .banner-description {
      color: ${color.greyMiddle};
      font-size: 16px;
    }

    .banner-buttons {
      display: block;
      width: 100%;
      margin-top: 15px;

      button {
        margin: 10px;

        @media (max-width: 768px) { 
          margin: 15px 0 0;
        }
      }
    }
  }
`;

const Trashes = styled.img`
  width: 410px;
  max-width: 100%;
  margin: 35px 0 40px;
`;

const Banner = () => (
  <Container className="" fluid>
    <WrapperRow>
      <Col md="8">
        <Row>
          <Col className="col-content">
            <Trashes src={require('../../assets/imgs/trashes.png')} alt="lixos reciclaveis" />
            <h2 className="banner-main-topic">
              Você quer dar um destino correto aos seus residuos recicláveis?
            </h2>

            <p className="banner-description">
              MeuReciclador ajuda os particulares a achar
              parceiros de coleta de uma maneira simples e moderna
            </p>

            <div className="banner-buttons">
              <Link to="/solicitar-coleta">
                <ButtonDefault color={color.gold}>Solicite uma Coleta</ButtonDefault>
              </Link>
              <Link to="/seja-reciclador">
                <ButtonDefault>Seja um Reciclador</ButtonDefault>
              </Link>
            </div>
          </Col>
        </Row>
      </Col>
    </WrapperRow>
  </Container>
)

export default Banner
