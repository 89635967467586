import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import color from 'styles/color';

const SubTitleStyled = styled.h3`
  font-size: 24px;
  color: ${color.gunsmoke};
  font-weight: bold;
  margin-bottom: 30px;
  padding-left: 20px;

  @media (max-width: 768px) {
    font-size: 23px;
  }
`;

const SubTitleDefault = ({ children }) => (
  <SubTitleStyled>
    {children}
  </SubTitleStyled>
);

SubTitleDefault.propTypes = {
  children: PropTypes.string.isRequired
};

export default SubTitleDefault;
