/* eslint-disable global-require */
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'
import color from 'styles/color'

const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .col-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    padding-top: 75px;
    padding-bottom: 85px;

    .step-wrapper {
      margin-bottom: 20px;

      .step {
        border: 1px solid ${color.wildSand};
        color: ${color.gunsmoke};
        border-radius: 3px;
        height: 100%;

        .topic {
          color: ${color.gunsmoke};
          font-weight: bold;
          font-size: 20px;
        }

        .description {
          color: ${color.gunsmoke};
          font-size: 16px;
          margin: 6px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    height: auto;
    padding-bottom: 15px;
  }
`

const StepImage = styled.img`
  height: 110px;
  margin: 20px;
`

const Steps = () => (
  <Container className="" fluid>
    <WrapperRow>
      <Col md="8">
        <Row>
          <Col className="col-content">
            <Row>
              <Col sm="4" className="step-wrapper">
                <div className="step">
                  <StepImage src={require('../../assets/imgs/step1.png')} alt="" />
                  <h3 className="topic">Ecológico e sustentável</h3>

                  <p className="description">
                    Ajudamos você a reciclar se lixo encaminhando ele para ser
                    reaproveitado corretamente de forma confiável
                  </p>
                </div>
              </Col>

              <Col sm="4" className="step-wrapper">
                <div className="step">
                  <StepImage src={require('../../assets/imgs/step2.png')} alt="" />
                  <h3 className="topic">Simples de utilizar</h3>

                  <p className="description">
                    Solicite coletas a partir do seu celular, no lugar
                    de sua escolha, de forma rápida e segura
                  </p>
                </div>
              </Col>

              <Col sm="4" className="step-wrapper">
                <div className="step">
                  <StepImage src={require('../../assets/imgs/step3.png')} alt="" />
                  <h3 className="topic">Bom para o bolso</h3>

                  <p className="description">
                    Seja recompensado a cada coleta de seus resíduos,
                    recicláveis recebendo pagamentos na plataforma
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </WrapperRow>
  </Container>
)

export default Steps
