/* eslint-disable global-require */
import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Breadcrumbs from 'components/Breadcrumbs';
import TitleDefault from 'components/TitleDefault';
import color from 'styles/color';

const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 0;

  .col-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;

    p {
      font-size: 18px;
      color: ${color.grey};
    }
  }
`;

const ConverImage = styled.img`
  width: 100%;
  min-width: 100%;
  height: auto;
`;

const Content = () => (
  <Fragment>
    <Breadcrumbs>Quem somos</Breadcrumbs>
    <Container className="">
      <WrapperRow>
        <Col md="12">
          <ConverImage src={require('../../assets/imgs/img-capa.png')} />
        </Col>
      </WrapperRow>
      <WrapperRow>
        <Col md="12">
          <Row>
            <Col className="col-content">
              <TitleDefault>Conheça melhor o MeuReciclador</TitleDefault>

              <p>
                O aplicativo <strong>MeuReciclador</strong> é uma iniciativa que
                tem como papel principal de ajudar as pessoas a destinar
                corretamente os seus resíduos de uma maneira simples e moderna.
              </p>

              <p>
                Foi identificado que para um condomínio que ja faz separação dos
                seus resíduos recicláveis, fica muito difícil achar um parceiro
                que aceita vir buscar os resíduos, isso por falta de maneira
                simples de se relacionar.
              </p>

              <p>
                Do outro lado, os coletores de resíduos têm dificuldades a receber
                facilmente as demandas de coletas e tem uma questão logística,
                pois não podem gastar muita gasolina atrevassando a cidade para
                buscar resíduos.
              </p>

              <p>
                <strong>MeuReciclador</strong> oferece uma solução que quer apoiar
                os geradores particulares de resíduos na busca de parceiros de
                coleta e otimizar a logística dos coletores de resíduos oferecendo
                volumes suplementares em proximidade geográfica.
              </p>
            </Col>
          </Row>
        </Col>
      </WrapperRow>
    </Container>
  </Fragment>
);

export default Content;
