import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Col, Container } from 'reactstrap';
import color from 'styles/color'


const BreadcrumbsWrapper = styled.div`
  .col {
    background-color: ${color.blackHaze};
    padding: 22px 0;
    border-bottom: 2px solid #e9ebeb;

    h1 {
      margin: 0;
      font-size: 22px;
      color: ${color.gunsmoke}

      @media (max-width: 425px) {
        font-size: 18px;
      }
    }

    @media (max-width: 425px) {
      padding: 18px 0;
    }
  }
`;

const Breadcrumbs = ({ children }) => (
  <BreadcrumbsWrapper>
    <Col>
      <Container>
        <h1>{children}</h1>
      </Container>
    </Col>
  </BreadcrumbsWrapper>
)

Breadcrumbs.propTypes = {
  children: PropTypes.string.isRequired
}

export default Breadcrumbs;
