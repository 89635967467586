import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'reactstrap';
import color from 'styles/color';

const ButtonStyled = styled(Button)`
  background-color: ${props => props.color} !important;
  color: #fff !important;
  border-radius: 40px !important;
  font-size: 22px !important;
  padding: 10px !important;
  width: 375px !important;
  max-width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.02) !important;
  height: 68px;
`;

const ButtonDefault = ({ children, color, className }) => (
  <ButtonStyled color={color} className={className}>
    {children}
  </ButtonStyled>
);

ButtonDefault.defaultProps = { color: color.primary };
ButtonDefault.propTypes = {
  children: PropTypes.string.isRequired,
  color: PropTypes.string,
  className: PropTypes.string
};

export default ButtonDefault;
