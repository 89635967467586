import React from 'react';
import { Switch } from 'react-router';
import { BrowserRouter, Route, Redirect } from 'react-router-dom';
import Landpage from 'screens/landpage';
import QuemSomos from 'screens/quem-somos';
import Contato from 'screens/contato';
import TermosUso from 'screens/termos-uso';
import SolicitarColeta from 'screens/solicitar-coleta';
import SejaReciclador from 'screens/seja-reciclador';

const Router = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/" component={Landpage} />
      <Route exact path="/contato" component={Contato} />
      <Route exact path="/quem-somos" component={QuemSomos} />
      <Route exact path="/termos-uso" component={TermosUso} />
      <Route exact path="/solicitar-coleta" component={SolicitarColeta} />
      <Route exact path="/seja-reciclador" component={SejaReciclador} />
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);

export default Router;
