/* eslint-disable global-require */
import React, { Component } from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col
} from 'reactstrap';
import styled from 'styled-components';
import color from 'styles/color';
import LinkDefault from 'components/LinkDefault';

const WrapperRow = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.primary};
  height: 129px;

  .row-content {
    display: flex;
    align-items: baseline;

    ul.navigation {
      display: flex;
      justify-content: space-between;
      list-style-type: none;
      color: #fff;
      margin: 0;
      padding: 0;

      li {
        a {
          font-size: 17px;
        }
      }
    }
  }
`;

const Title = styled.h1`
  color: #fff;
`;

const NavbarWrapper = styled(Navbar)`
  background: ${color.primary};
  padding-left: 15px !important;
  padding-right: 15px !important;

  .inactive {
    color: #fff;
    text-decoration: none;
  }

  .active {
    color: red;
    text-decoration: none;
  }

  .navbar-toggler {
    border: 0;
  }
`;

const NavLinkWrapper = styled(NavLink)`
  border-bottom: 1px solid #5d8d89;
`;

const NavbarTogglerWrapper = styled(NavbarToggler)`
  padding: 0 !important;
  margin-right: 10px !important;

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(131, 178, 174, 1)' stroke-width='4' stroke-miterlimit='40' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
    width: 2em;
    height: 2em;
  }
`;

const Logo = styled.img`
  width: 265px;

  @media (max-width: 425px) {
    width: 200px;
  }
`;

class HeaderDefault extends Component {
  constructor(props) {
    super(props);

    this.toggleNavbar = this.toggleNavbar.bind(this);
    this.state = { collapsed: true };
  }

  componentDidMount() { }

  toggleNavbar() {
    this.setState(prevState => ({ collapsed: !prevState.collapsed }));
  }

  render() {
    const { collapsed } = this.state;

    return (
      <div>
        <Container className="d-none d-md-block d-xl-block" fluid>
          <WrapperRow>
            <Col md="12">
              <Container>
                <Row className="row-content">
                  <Col md="5">
                    <Title>
                      <LinkDefault to="/">
                        <Logo
                          src={require('../assets/imgs/logo.png')}
                          alt="Logo"
                        />
                      </LinkDefault>
                    </Title>
                  </Col>

                  <Col md="7">
                    <ul className="navigation">
                      <li>
                        <LinkDefault to="/solicitar-coleta">
                          Solicite uma coleta
                        </LinkDefault>
                      </li>
                      <li>
                        <LinkDefault to="/seja-reciclador">
                          Seja um reciclador
                        </LinkDefault>
                      </li>
                      <li>
                        <LinkDefault to="/quem-somos">Quem somos</LinkDefault>
                      </li>
                      <li>
                        <LinkDefault to="/contato">Contato</LinkDefault>
                      </li>
                      <li>
                        <LinkDefault to="/termos-uso">Termos</LinkDefault>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Container>
            </Col>
          </WrapperRow>
        </Container>

        <NavbarWrapper className="d-md-none d-block" color="faded" light>
          <NavbarTogglerWrapper onClick={this.toggleNavbar} className="mr-2" />

          <NavbarBrand style={{ marginLeft: 10 }} href="/" className="mr-auto">
            <Logo src={require('../assets/imgs/logo.png')} alt="Logo" />
          </NavbarBrand>

          <Collapse style={{ marginTop: 20 }} isOpen={!collapsed} navbar>
            <Nav navbar>
              <NavItem>
                <NavLinkWrapper style={{ color: '#fff' }} href="/">
                  Solicite uma coleta
                </NavLinkWrapper>
              </NavItem>

              <NavItem>
                <NavLinkWrapper style={{ color: '#fff' }} href="/">
                  Seja um reciclador
                </NavLinkWrapper>
              </NavItem>

              <NavItem>
                <NavLinkWrapper style={{ color: '#fff' }} href="/">
                  Quem somos
                </NavLinkWrapper>
              </NavItem>

              <NavItem>
                <NavLinkWrapper style={{ color: '#fff' }} href="/">
                  Contato
                </NavLinkWrapper>
              </NavItem>

              <NavItem>
                <NavLinkWrapper
                  style={{ color: '#fff', border: 'none' }}
                  href="/"
                >
                  Termos
                </NavLinkWrapper>
              </NavItem>
            </Nav>
          </Collapse>
        </NavbarWrapper>
      </div>
    );
  }
}

export default HeaderDefault;
