import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import color from 'styles/color';

const TitleStyled = styled.h2`
  margin: 20px 0 30px;
  font-size: 28px;
  color: ${color.grey};
  font-weight: bold;
`;

const TitleDefault = ({ children }) => (
  <TitleStyled>
    {children}
  </TitleStyled>
);

TitleDefault.propTypes = {
  children: PropTypes.string.isRequired
};

export default TitleDefault;
