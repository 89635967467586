/* eslint-disable global-require */
import React, { Fragment } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardText
} from 'reactstrap';
import styled from 'styled-components';
import Breadcrumbs from 'components/Breadcrumbs';
import color from 'styles/color';
import TitleDefault from 'components/TitleDefault';
import SubTitleDefault from 'components/SubTitleDefault';

const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 0;

  .col-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;

    p {
      font-size: 18px;
      color: ${color.grey};
    }

    .list-unstyled {
      li {
        padding: 10px 0;
        color: ${color.gunsmoke};
        font-size: 17.5px;
        img {
          margin-right: 10px;
        }
      }
    }

    .card {
      .card-header {
        background-color: ${color.primary};
        color: #fff;
        font-size: 22px;
      }

      .card-text {
        color: ${color.gunsmoke};
        font-size: 18px;
      }
    }
  }

  .col-download-app {
    text-align: center;

    img {
      margin: 0 30px;
      width: 215px;

      @media (max-width: 768px) {
        margin: 0 5px;
        width: 160px;
      }
    }

    hr {
      display: flex;
      margin: 35px 0 10px;
    }
  }

  @media (max-width: 767.98px) {
    padding-bottom: 0;
  }
`;

const ConverImage = styled.img`
  width: 100%;
  min-width: 100%;
  height: auto;
`;

const Content = () => (
  <Fragment>
    <Breadcrumbs>Seja um reciclador</Breadcrumbs>
    <Container className="">
      <WrapperRow>
        <Col md="12">
          <ConverImage src={require('../../assets/imgs/img-capa.png')} />
        </Col>
      </WrapperRow>
      <WrapperRow>
        <Col md="12">
          <Row>
            <Col className="col-content">
              <TitleDefault>
                Baixe nosso app e comece a solicitar a coleta dos seus resíduos
              </TitleDefault>

              <p>
                O aplicativo <strong>MeuReciclador</strong> é uma iniciativa que
                tem como papel principal de ajudar as pessoas a destinar
                corretamente os seus resíduos de uma maneira simples e moderna.
              </p>
            </Col>
          </Row>

          <Row>
            <Col className="col-download-app">
              <a href="/">
                <img
                  src={require('../../assets/imgs/app-store.png')}
                  width="200"
                />
              </a>

              <a href="/">
                <img
                  src={require('../../assets/imgs/google-play.png')}
                  width="200"
                />
              </a>

              <hr />
            </Col>
          </Row>
        </Col>
      </WrapperRow>

      <WrapperRow>
        <Col md="12">
          <Row>
            <Col className="col-content">
              <SubTitleDefault>Como funciona?</SubTitleDefault>

              <Card>
                <CardHeader>Passo 1</CardHeader>
                <CardBody>
                  <CardText>
                    Através do aplicativo você irá visualizar as ofertas de
                    coletas mais próximas de sua localização atual, otimizando
                    sua logística.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-content">
              <Card>
                <CardHeader>Passo 2</CardHeader>
                <CardBody>
                  <CardText>
                    Você aceita ou rejeita as ofertas de acordo com os resíduos
                    que você tem interesse em comprar. Atenção, uma vez que você
                    recusou, a oferta vai para outro reciclador.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-content">
              <Card>
                <CardHeader>Passo 3</CardHeader>
                <CardBody>
                  <CardText>
                    Após aceitar a coleta você acessa os dados e vai até o local
                    de retirada, aonde deverá conferir os materiais antes de
                    realizar o pagamento do valor de compra dos resíduos pelo
                    próprio app.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-content">
              <Card>
                <CardHeader>Passo 4</CardHeader>
                <CardBody>
                  <CardText>
                    O valor de compra será debitado em seu saldo na plataforma e
                    após isso, será cobrado mensalmente de você e repassado para
                    todos os clientes que você atendeu na plataforma.
                  </CardText>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-content">
              <SubTitleDefault>O que eu posso reciclar?</SubTitleDefault>

              <ul className="list-unstyled">
                <li>
                  <img
                    src={require('../../assets/imgs/ico-check.png')}
                    width="25"
                  />
                  Papéis e papelão
                </li>
                <li>
                  <img
                    src={require('../../assets/imgs/ico-check.png')}
                    width="25"
                  />
                  Metais ferrosos e alumínio
                </li>
                <li>
                  <img
                    src={require('../../assets/imgs/ico-check.png')}
                    width="25"
                  />
                  Plástico filmes e duros
                </li>
                <li>
                  <img
                    src={require('../../assets/imgs/ico-check.png')}
                    width="25"
                  />
                  Vidros
                </li>
                <li>
                  <img
                    src={require('../../assets/imgs/ico-check.png')}
                    width="25"
                  />
                  Resíduos recicláveis misturados
                </li>
              </ul>
            </Col>
          </Row>
        </Col>
      </WrapperRow>
    </Container>
  </Fragment>
);

export default Content;
