/* eslint-disable global-require */
import React, { Fragment } from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import Breadcrumbs from 'components/Breadcrumbs';
import TitleDefault from 'components/TitleDefault';
import color from 'styles/color';

// h2 {
//   margin-bottom: 35px;
//   color: ${color.grey};
//   font-weight: bold;
//   font-size: 28px;
// }
const WrapperRow = styled(Row)`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 20px 0;

  .col-content {
    display: flex;
    flex-direction: column;
    padding-bottom: 25px;

    p {
      font-size: 18px;
      color: ${color.grey};
    }

    hr {
      display: flex;
      margin: 40px 0 10px;
    }
  }
`;

const ConverImage = styled.img`
  width: 100%;
  min-width: 100%;
  height: auto;
`;

const Content = () => (
  <Fragment>
    <Breadcrumbs>Termos de uso e Privacidade</Breadcrumbs>
    <Container className="">
      <WrapperRow>
        <Col md="12">
          <Row>
            <Col className="col-content">
              <TitleDefault>Termos de uso</TitleDefault>

              <p>
                O aplicativo <strong>MeuReciclador</strong> é uma iniciativa que
                tem como papel principal de ajudar as pessoas a destinar
                corretamente os seus resíduos de uma maneira simples e moderna.
                Foi identificado que para um condomínio que ja faz separação dos
                seus resíduos recicláveis, fica muito difícil achar um parceiro
                que aceita vir buscar os resíduos, isso por falta de maneira
                simples de se relacionar. Do outro lado, os coletores de
                resíduos têm dificuldades a receber facilmente as demandas de
                coletas e tem uma questão logística, pois não podem gastar muita
                gasolina atrevassando a cidade para buscar resíduos.
                <strong>MeuReciclador</strong> oferece uma solução que quer
                apoiar os geradores particulares de resíduos na busca de
                parceiros de coleta e otimizar a logística dos coletores de
                resíduos oferecendo volumes suplementares em proximidade
                geográfica.
              </p>

              <hr />
            </Col>
          </Row>

          <Row>
            <Col className="col-content">
              <TitleDefault>Política de Privacidade</TitleDefault>

              <p>
                O aplicativo <strong>MeuReciclador</strong> é uma iniciativa que
                tem como papel principal de ajudar as pessoas a destinar
                corretamente os seus resíduos de uma maneira simples e moderna.
                Foi identificado que para um condomínio que ja faz separação dos
                seus resíduos recicláveis, fica muito difícil achar um parceiro
                que aceita vir buscar os resíduos, isso por falta de maneira
                simples de se relacionar. Do outro lado, os coletores de
                resíduos têm dificuldades a receber facilmente as demandas de
                coletas e tem uma questão logística, pois não podem gastar muita
                gasolina atrevassando a cidade para buscar resíduos.
                <strong>MeuReciclador</strong> oferece uma solução que quer
                apoiar os geradores particulares de resíduos na busca de
                parceiros de coleta e otimizar a logística dos coletores de
                resíduos oferecendo volumes suplementares em proximidade
                geográfica.
              </p>
            </Col>
          </Row>
        </Col>
      </WrapperRow>
    </Container>
  </Fragment>
);

export default Content;
