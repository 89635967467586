import React from 'react'
import HeaderDefault from 'components/HeaderDefault'
import FooterDefault from 'components/FooterDefault'
import styled from 'styled-components'
import Banner from 'screens/landpage/banner'
import Steps from 'screens/landpage/steps'

const Body = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  font-family: 'Roboto', sans-serif;

  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
`

const Landpage = () => (
  <Body>
    <HeaderDefault />
    <Banner />
    <Steps />
    <FooterDefault />
  </Body>
)

export default Landpage
