import React from 'react'
import HeaderDefault from 'components/HeaderDefault'
import FooterDefault from 'components/FooterDefault'
import styled from 'styled-components'
import Content from 'screens/contato/content'

const Body = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Roboto');
  font-family: 'Roboto', sans-serif;

  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100vh;
`

const Contato = () => (
  <Body>
    <HeaderDefault />
    <Content />
    <FooterDefault />
  </Body>
);

export default Contato;
