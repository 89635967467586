/* eslint-disable global-require */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import styled from 'styled-components';
import LinkDefault from 'components/LinkDefault';
import color from '../styles/color'

const WrapperPreFooter = styled(Row)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color.primary};
  height: 80px;

  ul {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    color: #fff;

    &.navigation {
      display: flex;
      font-size: 14px;
      justify-content: space-between;
    }

    li {
      a {
        font-size: 17px;
      }
    }

    &.nav-links {
      display: flex;
      justify-content: space-around;
      align-items: center;

      li {

        .link-circled {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          border-radius: 50%;
          background: #96bdba;
          color: ${color.primary};
        }

        &.follow-us {
          font-size: 17px;
        }
      }
    }
  }

  @media (max-width: 767.98px) {
    height: auto;

    .navigation-wrapper {
      padding: 0;
    }

    ul {

      &.navigation {
        flex-direction: column;

        li {
          text-align: center;
          border-bottom: 1px solid #5D8D89;
          width: 100%;
          padding: 10px;
        }
     }

      &.nav-links {
        display: flex;
        justify-content: center;
        padding: 10px 0;

        li:nth-child(3)  {
          margin-left: 15px;
        }
      }
  }
`;

const WrapperMiddleFooter = styled(Row)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  background: #08504a;

  .logo-footer-wrapper {
    text-align: center;
    margin: 0;

    img {
      width: 140px;
    }
  }
`;

const WrapperFooter = styled(Row)`
  height: 50px;
  background: #06413c;

  .col-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      color: #94b4b1;
      text-align: center;
      font-size: 14px;
      margin: 0;
    }
  }

  @media (max-width: 767.98px) {
    background: #08504a;

    p {
      font-weight: bold;
    }
  }
`;

const FooterDefault = () => (
  <div>
    <Container fluid>
      <WrapperPreFooter>
        <Container>
          <Col xs="12" sm="12">
            <Row>
              <Col className="navigation-wrapper" md="7" xs="12" sm="12">
                <ul className="navigation">
                  <li>
                    <LinkDefault to="/solicitar-coleta">
                      Solicite uma coleta
                    </LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to="/seja-reciclador">
                      Seja um reciclador
                    </LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to="/quem-somos">Quem somos</LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to="/contato">Contato</LinkDefault>
                  </li>
                  <li>
                    <LinkDefault to="/termos-uso">Termos</LinkDefault>
                  </li>
                </ul>
              </Col>

              <Col md={{ size: 2, offset: 3 }} xs="12" style={{ padding: 0 }}>
                <ul className="nav-links">
                  <li className="follow-us d-none d-md-block d-xl-block">
                    Siga-nos
                  </li>
                  <li>
                    <div className="link-circled">
                      <i className="fab fa-instagram" />
                    </div>
                  </li>
                  <li>
                    <div className="link-circled">
                      <i className="fab fa-facebook-f" />
                    </div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Container>
      </WrapperPreFooter>
    </Container>

    <Container fluid>
      <WrapperMiddleFooter>
        <Col>
          <p className="logo-footer-wrapper">
            <img
              src={require('../assets/imgs/footer-logo.png')}
              alt="Logo do rodapé"
            />
          </p>
        </Col>
      </WrapperMiddleFooter>
    </Container>

    <Container fluid>
      <WrapperFooter>
        <Col className="col-wrapper">
          <p>Todos os direitos reservados. Copyright 2018 © Phixies</p>
        </Col>
      </WrapperFooter>
    </Container>
  </div>
);

export default FooterDefault;
